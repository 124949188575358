<!-- Footer Support Card -->
<div class="bg-body " style="background-color: #ffe9e9; padding: 2rem;">
  <!-- <div class="row justify-content-center website_animation">
    <div class=" mb-3 card-radius">
      <div class="row no-gutters">

        <div class="col-md-6">
          <div class="card-body">
            <h1 class="support_line"> Wanna Support Us ? </h1>
            <p class="patreon_highlight">Help to keep Project Elixir active and running by donating. Any
              amount is appreciated.<strong>シ </strong>
            </p>
            <h1> <a href="https://projectelixiros.com/donate">
                <a class="btn shadow footer-support-btn">Donate Now</a>
              </a></h1>

          </div>
        </div>

        <div class="col-md-6 mb-align" style="text-align: center;">
          <img src="./assets/images/donate-phone-hand.webp" class="card-img img-fluid down-carpet-img"
            style="width: 15rem;" alt="avatar">
        </div>

      </div>
    </div>
  </div> -->

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7915795682"
  style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto">
</ng2-adsense>

  <div class="toggle-container website_animation container">

    <div class="container">
      <h3 class="text-center text-uppercase"
        style="font-weight: 600; padding-top: 4rem; font-size: 2.5rem; margin-bottom: 1.7rem;">device downloads</h3>
    </div>

    <footer class="blockquote-footer"> Due to toxicity in community from <strong style="color: #cf7b7d;">v4.3 all builds
        will be exclusive to Patreon/BMC</strong> only</footer>
    <footer class="blockquote-footer"> Your can use v4.2 which is free and on website as of now</footer>
    <footer class="blockquote-footer"> For any further queries contact us via mail or on Telegram.</footer>

    <h1 style="margin-top: 2.5rem;">
      <p style="font-size: 1.2rem;"> Checkout below the list of active devices of v4.5 and above</p>
      <a style="font-weight: 600; line-height: 38px !important;"
        href="https://www.patreon.com/posts/project-elixir-5-109635188">
        <a class="btn shadow footer-support-btn">Active Devices on Patreon/BMC</a>
      </a>
    </h1>



    <br>

    <div style="display: grid;">
      <span class="toggle-text">{{ isChecked ? 'Showing Active Devices' : 'All Supported Devices' }}</span>
      <span class="text-muted" style="margin-bottom: 1.2rem; font-size: 0.9rem;">{{ isChecked ? 'Turn off the toggle to
        show discontinued or
        inactive devices of Project Elixir' : 'Currently showing all the Supported Devices
        of Project Elixir'}}</span>
    </div>
    <span>

    </span>
    <label class="switch">
      <input type="checkbox" [(ngModel)]="isChecked" (ngModelChange)="checkToggleState()">
      <span class="slider round"></span>
    </label>


  </div>

</div>

<br>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7915795682"
  style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto">
</ng2-adsense>


<!-- toggle-button.component.html -->
<!-- <div class="toggle-container container website_animation card container rounded shadow-light mb-5 active-toggle-card" >

  <div style="display: grid;">
    <span class="toggle-text">{{ isChecked ? 'Showing Active Devices' : 'All Supported Devices' }}</span>
    <span class="text-muted" style="margin-bottom: 1.2rem; font-size: 0.9rem;">{{ isChecked ? 'Turn off the toggle to show discontinued or
      inactive devices of Project Elixir' : 'Currently showing all the Supported Devices
      of Project Elixir'}}</span>
  </div>
  <span>

  </span>
  <label class="switch">
    <input type="checkbox" [(ngModel)]="isChecked" (ngModelChange)="checkToggleState()">
    <span class="slider round"></span>
  </label>

  <br>

  <h1 style="margin-bottom: 2rem;"> 
    <a style="font-weight: 600; line-height: 38px !important; margin-bottom: 2rem;" href="https://www.patreon.com/posts/project-elixir-5-109635188">
    <a class="btn shadow footer-support-btn">Active devices list of v4.5</a>
  </a></h1>

  <footer class="blockquote-footer"> Due to toxicity in community from <strong style="color: #cf7b7d;">v4.3 all builds will be exclusive to Patreon/BMC</strong> only</footer>
  <footer class="blockquote-footer"> Your can use v4.2 which is free and on website as of now</footer>
  <footer class="blockquote-footer"> For any further queries contact us via mail or on Telegram.</footer>

</div> -->

<!-- Download card here -->
<div class="container-fliud">

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7915795682"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto">
  </ng2-adsense>

  <div class="container website_animation">
    <div *ngFor="let item of deviceListToBeShown">
      <h4 class="text-center text-uppercase device_cata"> {{item.deviceCategory}} </h4>

      <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7915795682"
        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto">
      </ng2-adsense>

      <div class="container device_card">
        <div class="row justify-content-center">
          <div *ngFor="let d of item.deviceDetails">
            <div class="card download-card shadow bg-body rounded">

              <!-- <img src="./assets/images/device_download.webp" class="card-img-top rounded mx-auto d-block download-phone" alt="device"> -->


              <img class="card-img-top download-img-up" src="./assets/images/temp-download.jpg" alt="Card image cap">


              <div class="card-body device-card-below">

                <h5 class="card-title device-name">{{d.deviceName}}</h5>
                <h6 class="card-subtitle text-muted">{{d.codeName}}</h6>

                <a class="btn shadow download-page-btn" [routerLink]="['/device', d.deviceId]">Download</a>

              </div>



            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="container" style="padding-bottom: 3rem;">
    <p style="font-size: 1.2rem;"><strong>** Disclaimer **</strong> </p>
    <footer class="blockquote-footer"> Your warranty is now void after unlocking bootloader.</footer>
    <footer class="blockquote-footer"> Project Elixir is not responsible for any damage you made to your device.
      You have been warned!</footer>
    <footer class="blockquote-footer"> We are not responsible for anything that may happen to your phone by
      installing any kernels/mods/crack.</footer>
    <footer class="blockquote-footer"> You do it at your own risk and take the responsibility upon yourself.
    </footer>
    <footer class="blockquote-footer"> You are not to blame Project Elixir or its respected developers for any
      of your loss.</footer>
    <footer class="blockquote-footer"> Don't fall for mod/cracks etc and you may end up in trouble. In such
      scenarios You are not to blame Project Elixir</footer>
  </div>

</div>

<!-- scroll to top -->
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <button type="button" class="btn active scroll-btn" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
    <i class="fas fa-chevron-up"></i>
  </button>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7915795682"
  style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto">
</ng2-adsense>

<!-- Old Android 12 Builds Card -->
<div class=" mb-5 bg-body website_animation" style="background-color: #ffe9e9; padding: 2rem;">
  <div class="row justify-content-center">
    <div class=" mb-3 card-radius">
      <div class="row no-gutters">

        <div class="col-md-6 mb-align" style="text-align: center;">
          <img src="./assets/images/archived-files.webp" class="card-img img-fluid down-carpet-img"
            style="width: 15rem;" alt="avatar">
        </div>

        <div class="col-md-6">
          <div class="card-body">
            <h1 class="support_line"> Looking for old Android 12 or 13 Builds ? </h1>
            <p class="patreon_highlight">Download server costs a lot so to cut out some expenses we have moved older
              Android builds to sourceforge. Help to keep Project Elixir active and running by donating.
            </p>
            <h1> <a target="_blank" href="https://sourceforge.net/projects/project-elixir/files/">
                <a class="btn shadow footer-support-btn">Archived Builds</a>
              </a></h1>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="showFooter">
  <app-footer></app-footer>
</div>